import PrjProjects from '../../../project/defineProjects';
import CrmProjects from '../../crmProject';
import PrjTowers from '../../../project/projectTower';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'AddCrmTower',
  components: {
    PrjProjects,
    CrmProjects,
    PrjTowers,
    appStrings
  },
  props: ['crmTowerRowData'],
  data() {
    return {
      unsubscribe: null,
      showCrmTowerModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      projectPrj: {
        value: null,
        text: null
      },
      crmPrj: {
        value: null,
        text: null
      },
      prjTower: {
        value: null,
        text: null
      },
      crmTowerCode: null,
      crmTowerName: null,
      crmTowerId: 0,
      tab: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditCrmTower();
          this.showAlert = true;
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.crmTowerId });
        }
      }
    });
    if (this.crmTowerRowData !== null) {
      this.crmTowerCode = this.crmTowerRowData.crm_tower_code;
      this.crmTowerId = this.crmTowerRowData.crm_tower_id;
      this.crmTowerName = this.crmTowerRowData.crm_tower_name;
      this.prjTower = {
        value: this.crmTowerRowData.project_tower_id,
        text: this.crmTowerRowData.project_tower_name
      };
      this.crmPrj = {
        value: this.crmTowerRowData.crm_project_id,
        text: this.crmTowerRowData.crm_project_name
      };
      this.projectPrj = {
        value: this.crmTowerRowData.project_project_id,
        text: this.crmTowerRowData.master_project_name
      };
    } else {
      this.editMode = true;
    }
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'PrjTowers') {
        return (this.childName = 'PRJ Towers');
      } else if (tab === 'CrmProjects') {
        return (this.childName = 'CRM Projects');
      } else if (tab === 'PrjProjects') {
        return (this.childName = 'PRJ Projects');
      }
    },
    addEditCrmTower() {
      const payload = {
        crm_tower_code: this.crmTowerCode,
        crm_tower_id: this.crmTowerId,
        crm_tower_name: this.crmTowerName,
        crm_project_id: this.crmPrj.value,
        project_tower_id: this.prjTower.value
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/addEditCrmTower', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateCrmTowerList');
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    hideModalComponent(flag, component) {
      if (component === 'PrjTowers' && !this.crmPrj.text) {
        return alert(appStrings.DEPENDENTVSETMSG.CRMPROJECTMSG);
      }
      this.showCrmTowerModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedCrmProj(item) {
      this.showCrmTowerModal = false;
      this.crmPrj = {
        value: item.crm_project_id,
        text: item.crm_project_name
      };
      this.projectPrj = {
        value: item.project_project_id,
        text: item.project_project_name
      };
      this.prjTower = {
        value: null,
        text: null
      };
    },
    towerDetails(item) {
      this.showCrmTowerModal = false;
      this.prjTower = {
        value: item.tower_id,
        text: item.tower_name
      };
    },
    selectedCrmMasterProj(item) {
      this.showCrmTowerModal = false;
      this.crmMasterPrj = {
        value: item.crm_master_project_id,
        text: item.crm_master_project_name
      };
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
