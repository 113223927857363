import DefineProject from '../../defineProjects';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
    name: 'ProjectTowerDetails',
    components: {
        DefineProject,
        DatePicker
    },
    props: {
        parentProjectTowerData: Object
    },
    data() {
        return {
            unsubscribe: null,
            showModalComponent: false,
            showValueSetModal: false,
            editMode: false,
            loader: false,
            showAlert: false,
            isSuccess: false,
            responseMsg: '',
            tab: null,
            project: {
                value: null,
                text: null
            },
            towerCode: null,
            towerId: 0,
            towerName: null,
            start_date_end_date: null,
            startDate: null,
            endDate: null,
            version: null,
            approvalStatus: null
        }
    },
    mounted() {
        if (this.parentProjectTowerData) {
            this.fillDataFromParent();
        } else { this.editMode = true }
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'save' && this.editMode) {
                    this.addEditProjectTower();
                }
                if (actionName === 'update') {
                    this.editMode = true;
                }
                if (actionName === 'upload') {

                    this.eventBus.$emit('commonUpload', { id: this.towerId });

                }
            }
        });

    },
    methods: {
        fillDataFromParent() {
            this.project = {
                value: this.parentProjectTowerData.project_id,
                text: this.parentProjectTowerData.project_name
            };
            this.startDate = this.parentProjectTowerData.start_date;
            this.endDate = this.parentProjectTowerData.end_date;
            this.towerCode = this.parentProjectTowerData.tower_code;
            this.towerId = this.parentProjectTowerData.tower_id;
            this.towerName = this.parentProjectTowerData.tower_name;
            this.version = this.parentProjectTowerData.version;
            this.approvalStatus = this.parentProjectTowerData.approval_status;
        },
        setChildName(tab) {
            //method for child modal name
            if (tab === 'DefineProject') {
                return (this.childName = 'Define Project');
            }
        },
        addEditProjectTower() {
            const payload = {
                project_id: this.project.value,
                start_date: this.startDate,
                end_date: this.endDate,
                tower_code: this.towerCode,
                tower_id: this.towerId,
                tower_name: this.towerName
            };
            this.loader = true;
            this.$store.dispatch('projects/addEditProjectsTower', payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.$emit('updateProjectTowerList');
                        const result = response.data.data;
                        this.editMode = false;
                        this.towerId = result.tower_id;
                        this.approvalStatus = result.approval_status;
                        this.version = result.version;
                        this.showAlert = true;
                        this.isSuccess = true;
                        this.responseMsg = response.data.message;
                    }
                    else {
                        this.showAlert = true;
                        this.isSuccess = false;
                        this.responseMsg = response.response.data.message;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    this.showAlert = true;
                    this.isSuccess = false;
                    this.responseMsg = appStrings.autoFailedMsg;
                });
        },
        disabledDates(date) {
            return commonHelper.disabledDates(
                date,
                this.startDate
            );
        },
        hideModalComponent(flag, component) {
            this.showModalComponent = flag;
            this.tab = component;
            this.setChildName(this.tab);
        },
        projectDetails(item) {
            this.showModalComponent = false;
            this.project = {
                value: item.project_id,
                text: item.project_name
            }
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
}