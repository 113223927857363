import commonHelper from '@/app/utility/common.helper.utility';
import DefineProject from '../defineProjects';
import ProjectTowerDetails from './projectTowerDetails';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'ProjectTower',
  components: {
    DefineProject,
    ProjectTowerDetails
  },
  props: {
    showTowerComponent: Boolean,
    projecName: String,
    projectId: Number
  },
  watch: {
    currentPage: function() {
      this.getProjectsTowerList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getProjectsTowerList();
    },
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      loader: false,
      editMode: false,
      showAlert: false,
      payload: {},
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      tab: null,
      project: {
        value: null,
        text: null
      },
      towerCode: null,
      towerName: null,
      parentProjectTowerData: null,
      projectTowerData: [],
      projectTowerFields: [
        {
          key: 'project_id',
          class: 'd-none'
        },
        {
          key: 'project_name'
        },
        {
          key: 'tower_id',
          class: 'd-none'
        },
        {
          key: 'tower_code'
        },
        {
          key: 'tower_name'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'version'
        },
        {
          key: 'approval_status'
        }
      ]
    };
  },
  validations: {
    project: {
      text: {
        required
      }
    }
  },
  mounted() {
    if (this.showTowerComponent) {
      this.project.text = this.projecName;
      this.getProjectsTowerList(this.projectId);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideModalComponent(true, 'ProjectTowerDetails');
          this.parentProjectTowerData = null;
        }
        if (actionName === 'download' && !this.showModalComponent) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'projects/getProjectsTowerList',
            'project-tower',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'DefineProject') {
        return (this.childName = 'Define Project');
      } else if (tab === 'ProjectTowerDetails') {
        return (this.childName = 'Project Tower Details');
      }
    },
    getProjectsTowerList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        project_id: this.projectId ? this.projectId : this.project.value,
        tower_code: this.towerCode,
        tower_name: this.towerName
      };
      this.loader = true;
      this.$store
        .dispatch('projects/getProjectsTowerList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.projectTowerData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          } else {
            this.loader = false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
    hideModalComponent(flag, component) {
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    rowSelected(rowData) {
      this.parentProjectTowerData = rowData;
      this.$emit('towerDetails', this.parentProjectTowerData);
      this.hideModalComponent(true, 'ProjectTowerDetails');
    },
    projectDetails(item) {
      this.showModalComponent = false;
      this.project = {
        value: item.project_id,
        text: item.project_name
      };
    },
    updateProjectTowerList() {
      this.getProjectsTowerList();
    },
    resetSearchFilters() {
      this.project = {
        value: null,
        text: null
      };
      this.towerCode = null;
      this.towerName = null;
      this.projectTowerData = [];
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
