import commonHelper from '@/app/utility/common.helper.utility';
import CrmProjects from '../crmProject';
import PrjProjects from '../../project/defineProjects';
import AddCrmTower from './addCrmTower';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'crmTower',
  components: {
    CrmProjects,
    PrjProjects,
    AddCrmTower
  },
  props: ['crmProjectName', 'crmProjectId', 'showCrmTowerComp'],
  watch: {
    currentPage: function() {},
    perPage: function() {
      this.currentPage = 1;
    }
  },
  data() {
    return {
      unsubscribe: null,
      showcrmTowerModal: false,
      loader: false,
      editMode: false,
      showAlert: false,
      currentPage: 1,
      payload: {},
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      crmPrj: {
        value: null,
        text: null
      },
      projectPrj: {
        value: null,
        text: null
      },
      crmTowerCode: '',
      crmTowerName: '',
      tab: null,
      crmTowerRowData: null,
      crmTowerData: [],
      crmTowerFields: [
        {
          key: 'crm_master_project_id',
          class: 'd-none'
        },
        {
          key: 'crm_master_project_name',
          class: 'd-none'
        },
        {
          key: 'crm_tower_code',
          label: 'CRM Tower Code'
        },
        {
          key: 'crm_tower_id',
          class: 'd-none'
        },
        {
          key: 'crm_tower_name',
          label: 'CRM Tower Name'
        },
        {
          key: 'master_project_name',
          class: 'd-none'
        },
        {
          key: 'master_project_id',
          class: 'd-none'
        },
        {
          key: 'sector_id',
          class: 'd-none'
        },
        {
          key: 'project_tower_name',
          label: 'Project Tower Name'
        },
        {
          key: 'project_tower_id',
          class: 'd-none'
        }
      ]
    };
  },
  validations: {
    crmPrj: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideModalComponent(true, 'AddCrmTower');
          this.crmTowerRowData = null;
        }
        if (actionName === 'download' && !this.showcrmTowerModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'crmInventoryProperty/getCrmTowerList',
            'crm-tower',
            () => (this.loader = false)
          );
        }
      }
    });
    if (this.showCrmTowerComp) {
      this.crmPrj.text = this.crmProjectName;
      this.getCrmTowerList();
    }
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'CrmProjects') {
        return (this.childName = 'CRM Projects');
      } else if (tab === 'PrjProjects') {
        return (this.childName = 'PRJ Projects');
      } else if (tab === 'AddCrmTower') {
        return (this.childName = 'Add CRM Tower');
      }
    },
    hideModalComponent(flag, component) {
      this.showcrmTowerModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    getCrmTowerList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          crm_project_id: this.crmProjectId
            ? this.crmProjectId
            : this.crmPrj.value,
          crm_tower_code: this.crmTowerCode,
          crm_tower_name: this.crmTowerName
        };
        this.loader = true;
        this.$store
          .dispatch('crmInventoryProperty/getCrmTowerList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.crmTowerData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    rowSelected(rowData) {
      this.crmTowerRowData = rowData;
      this.$emit('selectedCrmTower', this.crmTowerRowData);
      this.hideModalComponent(true, 'AddCrmTower');
    },
    selectedCrmProj(item) {
      this.showcrmTowerModal = false;
      this.crmPrj = {
        value: item.crm_project_id,
        text: item.crm_project_name
      };
      this.projectPrj.text = item.project_project_name;
    },
    updateCrmTowerList() {
      this.getCrmTowerList();
    },
    resetSearchFilters() {
      this.crmPrj = {
        value: null,
        text: null
      };
      this.projectPrj = {
        value: null,
        text: null
      };
      this.crmTowerCode = '';
      this.crmTowerName = '';
      this.crmTowerData = [];
      this.totalRows = null;
      this.currentPage = 1;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
